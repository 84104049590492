import React from 'react'
import ClientCard from 'components/client-card'
import PropTypes from 'prop-types'
import { window } from 'browser-monads'
import { woodwatch_1, woodwatch_2, woodwatch_3, bibbr_1, bibbr_2, bibbr_3, yummygum_2, naduvi_1, naduvi_2, naduvi_3, naduvi_4} from 'images/klanten'
import { avy_logo, bibbr_logo } from 'images/klanten'
import Helmet from 'react-helmet'
import Layout from '../../layouts'

const dummyKlantVerhaal = {
  pageTitle: '“We nemen continu mensen aan en willen snelheid en flexibiliteit.”',
  pageStreamer: 'Naduvi, interview met Laurèl Borggreve',
  useCase: '+100 werknemers',
  person: {
    name: 'Laurèl Borggreve',
    functionTitle: 'Finance Lead',
    companyLogo: 'avy_logo',
    description: 'Onze ambitie is om de grootste speler van Europa te worden. We zijn nu actief in Nederland, België en Duitsland en willen uitbreiden naar andere landen.',
    portraitImage: naduvi_1,
    portraitImageSmall: naduvi_2,
    link: 'https://www.naduvi.nl/',
    internallink: false,
    btnText: 'Bekijk de website&nbsp;→'
  },
  content: [{
    title:'',
    description: 'Laurèl Borggreve is Finance lead bij Naduvi. Behalve finance stuurt ze ook het team van legal en HR aan én werkt ze aan projecten voor operations. Naduvi, een online outlet voor home & living, groeit snel en heeft een mooie ambitie: de grootste speler van Europa te worden! In dit interview vertelt Laurèl over de uitdagingen in haar werk en hoe ze alle ballen in de lucht houdt. '
    }, {
      title: 'Dynamische rol in een groeiende organisatie',
      description: 'Het team waar Laurèl verantwoordelijk voor is bestaat nu uit 9 man, waarvan 7 FTE. Laurèl: “Ik stuur het team van finance en HR aan en doe ook projecten op operations. Ik ben verantwoordelijk van de loonadministratie tot verzekeringen en zaken die met de arbeidswet te maken hebben. Legal valt ook onder finance, waardoor ik soms schakel met advocaten. Vragen van leveranciers komen ook bij mij. Die afwisseling is heel tof en maakt mijn rol enorm dynamisch.” <br><br>Volgens een uniek en duurzaam concept verkoopt <a href="https://www.naduvi.nl/" target="_blank" rel="nofollow" alt="Naduvi">Naduvi</a> de twintig procent overschot van het assortiment van leveranciers. Meubels en andere home en interieurartikelen die anders in de opslag zouden blijven staan worden verkocht tegen een mooie korting. Een win-win situatie voor zowel de klant,  die voor een mooie prijs een nieuw item in huis haalt, én de leverancier, die de vooraad ziet slinken. <br><br>Naduvi bestaat alleen uit een webshop, er is geen warehouse. Laurèl zoekt met haar team naar mogelijkheden om uit te breiden naar andere landen. Dit past ook bij de groeiambitie van <a href="https://www.naduvi.nl/" target="_blank" rel="nofollow" alt="Naduvi">de online outlet voor home en living</a>: “Onze ambitie is om de grootste speler van Europa te worden. We zijn aardig op weg. We zijn nu actief in Nederland, België en Duitsland en willen uitbreiden naar andere landen in Europa. We groeien hard en hebben nu ruim 100 medewerkers in dienst.” '
},{
  title: 'Automatiseren om werkprocessen te verbeteren',
  description: 'De groeistrategie van Naduvi vraagt ook om flexibiliteit en creativiteit. Vooral als het gaat om werkprocessen. Laurel ervaart de maandelijkse facturatie naar leveranciers als best ingewikkeld: <br><br>“Omdat we groeien bedenken we nieuwe dingen die in de facturatie mee moeten gaan. Elke leverancier heeft een afzonderlijk contract en bepaalde voorwaarden. Daarom hebben we een model gebouwd om de facturen te checken maar dat wordt wel erg complex. We ontwikkelen nu nieuwe dashboards per leverancier die we willen gaan automatiseren. De uitdaging is om de facturen goed te blijven checken én het model goed werkbaar te houden. Ik wil natuurlijk het liefst al mijn tijd steken in analyseren en checken. En niet in handmatige dingen die je zou kunnen laten vervangen door bijvoorbeeld een robot.” '
},
{
  image: naduvi_4
},
{
  title: 'Nieuwe oplossing voor HR en salarisadministratie ',
  description: 'De overweging om te automatiseren was er ook toen Laurèl op zoek ging naar een eenvoudige oplossing voor salarisadministratie. De boekhouder waar ze mee werkte had een verouderd systeem:<br><br>“Het was mijn idee om over te stappen op Employes. We maken gebruik van een boekhouder en hiermee deden we ook de loonadministratie, maar ik had de contactpersoon vaak nodig om wijzigingen door te voeren. Het ging zó traag en moeizaam. Ik wilde die administratie in eigen beheer doen omdat wij snel werken en er vaak wijzigingen zijn. We zijn een start-up, nemen continu mensen aan en willen snelheid en flexibiliteit. Dus daar was een mismatch qua samenwerking. Uiteindelijk heb ik besloten om de boekhouding daar te laten maar de administratie zelf te doen. Via Google kwam ik bij Employes uit. De website heeft een moderne look en feel, en ik had er gelijk een goed gevoel bij. Na een gesprek met Rob ontving ik een demo en toen besloten we om over te gaan.” '
},{
  quote: '"Hoe meer je alles in één beheer kunt doen, hoe beter dat is. Je bent veel minder afhankelijk van losse systemen of sheets."'
},{
  image: naduvi_3
    },
    {
      title: 'Advies voor andere finance professionals: gewoon doen',
      description: 'Laurèl heeft een duidelijk advies voor professionals die overwegen over te stappen.“Als je twijfelt om over te stappen omdat je huidige systeem traag is of niet werkt dan is mijn advies om sowieso over te stappen. Misschien ben je bang omdat je niet alle kennis van salarisadministratie hebt. Die had ik zelf ook niet, maar je kunt veel online vinden en er staan handige instructies op de website van Employes. Ook het support team denkt goed met je mee. De tool is gebruiksvriendelijk en werkt super vlot. Je komt er vanzelf achter waar je tegenaan loopt maar hebt genoeg ondersteuning en hulp, dus dat komt wel goed!” <br><br>Het team is in eerste instantie begonnen met alleen de finance oplossing. Later is ook de HR uitbreiding van Employes toegevoegd. “We wilden eerst uitvinden hoe finance werkte en hadden afgesproken om daarna te kijken naar eventuele uitbreiding. We kregen er steeds meer mensen bij en hadden overal in Excel sheets de vakanties, verzuim en declaraties staan. Omdat we zó snel groeiden besloten we om HR toe te voegen. Eerst met een proefperiode en omdat het goed beviel zijn we helemaal overgestapt. Hoe meer je alles in één beheer kunt doen, hoe beter dat is. Je bent veel minder afhankelijk van losse systemen of sheets. Mijn advies is dus: gewoon doen!”'
}],

  otherClientStories: [{
    name: 'Ruben Paijens',
    functionTitle: 'Mede-oprichter, Bibbr',
    companyLogo: '',
    description: 'Ik vind het vooral fijn dat jullie betrokken zijn.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: bibbr_2,
    quote: 'Ik vind het vooral fijn dat jullie betrokken zijn en makkelijk te bereiken zijn.',
    link: '/klanten/bibbr/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Vince Schwidder',
    functionTitle: 'Mede-oprichter, Yummygum',
    companyLogo: '',
    description: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: yummygum_2,
    quote: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    link: '/klanten/yummygum/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Kevin van der Veer',
    functionTitle: 'Mede-oprichter, WoodWatch',
    description: 'Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel.',
    portraitImage: woodwatch_2,
    portraitImageSmall: woodwatch_2,
    quote: 'Payroll, dat kost me gewoon teveel.',
    internallink: true,
    link: '/klanten/woodwatch/',
    btnText: 'Lees ervaring&nbsp;→'
  }]
}


const klantverhaalTemplate = ({location}) => (
  <Layout location={location}>
    <main className="clientstory animated fadeInPage">

      <Helmet>
        <title>Interview met Laurèl Borggreve van Naduvi | Employes</title>
        <meta name="description" content="Onze ambitie is om de grootste speler van Europa te worden. Laurèl van Naduvi over haar uitdagingen als Finance lead." />
        <meta itemprop="name" content="Interview met Laurèl Borggreve van Naduvi | Employes" />
        <meta itemprop="description" content="Onze ambitie is om de grootste speler van Europa te worden. Laurèl van Naduvi over haar uitdagingen als Finance lead." />
        <meta itemprop="image" content="https://employes.nl/static/meta-naduvi.jpg" />

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://employes.nl/klanten/naduvi/" />
        <meta property="og:title" content="Interview met Laurèl Borggreve van Naduvi | Employes" />
        <meta property="og:description" content="Onze ambitie is om de grootste speler van Europa te worden. Laurèl van Naduvi over haar uitdagingen als Finance lead." />
        <meta property="og:image" itemprop="image" content="https://employes.nl/static/meta-naduvi-og.jpg" />
        <meta property="og:image:type" content="image/jpeg"/>
        <meta property="og:image:width" content="299"/>
        <meta property="og:image:height" content="157"/>
        <meta property="og:type" content="website" />


        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content= "Interview met Laurèl Borggreve van Naduvi | Employes" />
        <meta name="twitter:description" content= "Onze ambitie is om de grootste speler van Europa te worden. Laurèl van Naduvi over haar uitdagingen als Finance lead."  />
        <meta name="twitter:image" content="https://employes.nl/static/meta-naduvi.jpg" />

      </Helmet>


      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid between align-middle">
            <div className="col-6 no-pad">
              <h2>{dummyKlantVerhaal.pageTitle}</h2>
              <p className="streamer large margin-l-bottom">{dummyKlantVerhaal.pageStreamer}</p>
              <span className="badge green">Bedrijfsgrootte: <b>{dummyKlantVerhaal.useCase}</b></span>
            </div>
            <ClientCard className="col-6 no-pad" person={dummyKlantVerhaal.person} isSmall={(window.innerWidth < 768)} />
          </div>
        </div>
      </header>
      <section className="up">
        <div className="clientstory-content padding-xl">
          {dummyKlantVerhaal.content.map((section, idx) => {
            const firstObjectKey = Object.keys(section)[0]
            const isImage = (firstObjectKey === 'image')
            const { title, description } = section

            return (
              <div className="paragraph margin-m-bottom" key={idx}>
                <div className={`container-${isImage ? 'md image' : 'sm'}`} key={idx}>
                  <div className="grid yg center">
                    <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                      {{
                        title: (
                          <div className="text">
                            <h4>{title}</h4>
                            <p className="large" dangerouslySetInnerHTML={{ __html: description }}></p>
                          </div>
                        ),
                        quote: (
                          <div className="client-quote">
                            "Hoe meer je alles in één beheer kunt doen, hoe beter dat is. Je bent veel minder afhankelijk van losse systemen of sheets."
                        </div>
                        ),
                        image: (
                          <img src={section.image} alt="Laurèl Borggreve" />
                        )
                      }[firstObjectKey]}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          }
        </div>

        <div className="clientstory-others padding-xl">
          <div className="container-md">
            <div className="grid yg center text-center margin-l-bottom">
              <div className="col-12">
                <h6 className="eyebrow">Ervaringen</h6>
                <h2 className="no-margin">Andere klantverhalen</h2>
              </div>
            </div>
            <div className="grid yg">
              {dummyKlantVerhaal.otherClientStories.map((otherclient, idx) => {
                const { name, functionTitle, companyLogo, portraitImage, quote } = otherclient

                return (
                  <div className="col-4" key={otherclient.name} key={idx}>
                    <ClientCard person={otherclient} isSmall={true}/>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </section>
    </main>
  </Layout>
)

export default klantverhaalTemplate
